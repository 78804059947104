@import '../../styles/variables';

.App-side-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  clear: both;

  .App-side-nav-link {
    display: inline-block;
    padding: 0.75rem 1.25rem;
    border-radius: $border-radius;
    color: $color-text-primary;
    font-size: 1.4rem;
    letter-spacing: 0.15rem;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    cursor: pointer;

    &.active {
      color: $color-text-light;
      &:after {
        color: $color-text-light;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-primary;
        border-radius: $border-radius;
        z-index: -2;
      }
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: $color-neutral-dark;
      transition: all 0.3s;
      border-radius: $border-radius;
      z-index: -1;
    }
    &:hover {
      color: $color-text-primary;
      &:before {
        width: 100%;
      }
    }
  }
}
