@import '../../styles/variables';

.App-nav {
  height: 7.1875rem;
  list-style: none;
  margin: 0;
  float: right;
  display: flex;
  flex-direction: row;
  transition: height ease-in-out 0.2s;

  li {
    a {
      cursor: pointer;
      height: 7.1875rem;
      line-height: 7.1875rem;
      display: block;
      background: $color-white;
      padding: 0 1.3rem;
      position: relative;
      font-weight: 400;
      transition: all ease-in-out 0.2s;
      font-size: 18px;

      &:after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 50%;
        right: 50%;
        height: 0rem;
        background: $color-primary;
        transition: all ease-in-out 0.2s;
        opacity: 0;
      }

      &:before {
        content: ' ';
        position: absolute;
        bottom: 0rem;
        left: 50%;
        right: 0;
        width: 0;
        height: 0;
        margin-left: -0.625rem;
        border-style: solid;
        border-width: 0 0.625rem 0.625rem 0.625rem;
        border-color: transparent transparent $color-primary transparent;
        transition: all ease-in-out 0.2s;
        opacity: 0;
      }

      &.active {
        color: $color-primary;

        &:after {
          opacity: 1;
          left: 0;
          right: 0;
          height: 0.3125rem;
        }

        &:before {
          opacity: 1;
          bottom: 0.2rem;
        }
      }
    }
  }
}
