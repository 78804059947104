@import '../../styles/variables';

.App-heading-block {
  text-align: center;
  padding: 0;

  @media only screen and (min-width: #{$break-md}) {
    padding: 0;
  }

  &--gutterBottom {
    margin-bottom: 1.875rem;
  }
}
