@import '../../styles/variables';

.App-header {
  height: 7.1875rem;
  background-color: $color-white;
  position: fixed;
  padding: 0 1.25rem;
  top: 0rem;
  left: 0rem;
  right: 0rem;
  z-index: 999;
  transition: all ease-in-out 0.2s;

  &:after {
    content: '';
    position: absolute;
    bottom: -0.3125rem;
    left: 0rem;
    right: 0rem;
    height: 0.3125rem;
    background-color: $color-shadows;
  }
}

.App-logo-link {
  display: inline-block;
  margin: 2.13345rem 0;
  height: 2.9206rem;
  width: 11.625rem;
  cursor: pointer;
  transition: all ease-in-out 0.2s;

  @media only screen and (min-width: #{$break-md}) {
    margin: 2.2647rem 0;
    height: 2.9206rem;
    width: 11.625rem;
  }

  img {
    width: 100%;
  }
}

.App-nav-hamburger {
  position: absolute !important;
  top: 50%;
  right: 1.5rem;
  margin-top: -1.5rem !important;
  display: block !important;
}
.App-nav-lang {
  margin-left: auto;
  float: right;
  margin: 2.375rem 0;
}
