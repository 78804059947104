@import '../../styles/variables';

.App-BenefitCard {
  display: flex;
  flex-direction: column;

  .App-BenefitCard-icon {
    margin-bottom: 1.25rem;
    min-width: 4.6875rem;
    text-align: center;

    @media only screen and (min-width: #{$break-md}) {
      min-width: 7.1875rem;
    }

    img {
      max-width: 100%;
    }
  }
}
