@import '../../styles/variables';

.App-side-nav-close {
  float: right;
  display: block;
}

.MuiDrawer-paperAnchorLeft {
  right: 0 !important;
  min-width: 20rem;
  background: $color-neutral !important;
}
