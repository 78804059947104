@import '../../styles/variables';

.App-Strip {
  padding: 0;
  background: $color-white;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0.1875rem;
    background: $color-shadows-dark;
    display: block;
    filter: blur(0.1875rem);
  }

  &--padded {
    padding: 2.5rem 0;

    @media only screen and (min-width: #{$break-sm}) {
      padding: 5rem 0;
    }
  }

  &--grey {
    background: $color-neutral;
  }

  &--pattern {
    background: url('../../assets/splash/StripBg.jpg');
    background-size: cover;
  }

  &--greyGrad {
    background: $color-neutral;
    background: linear-gradient(0deg, $color-white 0%, $color-neutral 100%);
  }

  &--greyGradRev {
    background: $color-neutral;
    background: linear-gradient(0deg, $color-neutral-mid 0%, $color-neutral 100%);
  }

  &--primaryGrad {
    background: $color-primary-dark;
    background: linear-gradient(0deg, $color-primary-dark 0%, $color-primary-light 100%);
  }

  &--primary {
    background: $color-primary;
  }

  &--white {
    background: $color-white;
  }

  &--borderBottom {
    border-bottom: $border-thin solid $color-neutral-dark;
  }
}
