@import '../styles/variables';

.App-splash {
  align-items: center;
  display: flex;
  min-height: initial;
  padding-bottom: 2.5rem;
  padding-top: 9rem;
  position: relative;

  @media only screen and (min-width: #{$break-md}) {
    padding-top: 12.8rem;
    padding-bottom: 5.5rem;
  }
}

.App-splash-tag {
  align-items: flex-end;
  background: url('../assets/splash/semicircle.png') top left no-repeat;
  background-size: cover;
  bottom: -0.44rem;
  color: $color-primary;
  content: '';
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  height: 4.35rem;
  justify-content: center;
  left: 50%;
  margin-left: -5.4375rem;
  position: absolute;
  padding-bottom: 15px;
  width: 8.7rem;
  z-index: 99;
}

.App-splash-buttons {
  display: grid;
  grid-gap: 0.625rem * 2;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(auto, 1fr));

  @media only screen and (min-width: #{$break-md}) {
    display: block;
    margin-top: 2rem;
  }
}
