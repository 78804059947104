@import '../../styles/variables';

.App-Footer-main {
  position: relative;
  color: $color-white;
  background: rgb(16, 53, 68);

  padding: 2.5rem 0;

  @media only screen and (min-width: #{$break-sm}) {
    padding: 5rem 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0.1875rem;
    background: $color-shadows-dark;
    display: block;
    filter: blur(0.1875rem);
  }
}
.App-Footer-extra {
  position: relative;
  color: $color-white;
  background: rgb(11, 37, 47);
  text-align: left;
  padding: 2rem 0;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0.1875rem;
    background: $color-shadows-dark;
    display: block;
    filter: blur(0.1875rem);
  }
}

.App-footer-link {
  color: $color-white;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;
}
