@import '../../styles/variables';

.App-ParallaxContainer {
  height: 16.25rem;
  position: relative;
  display: flex;
  align-items: center;

  @media only screen and (min-width: #{$break-sm}) {
    height: 26.44rem;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0.1875rem;
    background: $color-shadows;
    display: block;
  }
}
