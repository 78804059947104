@import '../../styles/variables';

.App-privacy-title {
  .MuiTypography-h5 {
    font-weight: 600;
  }
}

.App-privacy {
  * {
    font-size: 0.875rem;
    line-height: 1.5 !important;
  }

  .MuiTypography-body1,
  .MuiTypography-h6,
  .MuiTypography-h5 {
    font-weight: 600;
  }

  a {
    color: #3300ff;
    font-weight: 600;
  }
}
