@import './styles/animations';
@import './styles/variables';

html,
body {
  margin: 0;
}

.App {
  background: url('./assets/splash/global.jpg') top center fixed;
  background-size: cover;

  @media only screen and (min-width: #{$break-md}) {
    background: url('./assets/splash/global.jpg') top center fixed;
    background-size: cover;
  }
}

.App2 {
  background: none;

  @media only screen and (min-width: #{$break-md}) {
    background: url('./assets/splash/hero-image-family-shaded2.jpeg') top center fixed;
    background-size: cover;
  }
}

/* Special Cases */
.App-centered-logo {
  position: absolute;
  top: 50%;
  left: 50%;

  margin: -1.5875rem 0 0 -10rem;
  width: 20rem;
  @media only screen and (min-width: #{$break-sm}) {
    margin: -2.5625rem 0 0 -16.375rem;
    width: 32.75rem;
  }
}

/* Modifications */
.App-plain-link {
  text-decoration: none;
}

.App-text--bolder {
  font-weight: 700;
}

.App-text--bold {
  font-weight: 300;
}

.App-text--light {
  font-weight: 100 !important;
}

/* Animation */
.rotateIn {
  animation: rotateIn 5s 1;
  animation-fill-mode: forwards;
}

.rotateBack {
  animation: rotateBack 5s 1;
  animation-fill-mode: forwards;
}

a {
  color: black;
}
