@import '../../styles/variables';

.App-Profile {
  display: flex;
  flex-direction: column;
  margin-right: 0;
  margin-left: 0;
  min-height: 500;

  @media only screen and (min-width: #{$break-md}) {
    padding: 1.875rem;
  }

  @media only screen and (min-width: #{$break-md}) {
    flex-direction: row;
    padding: 0.625rem;
    margin-right: 4.375rem;
    margin-left: 4.375rem;
  }

  &-pic {
    @media only screen and (min-width: #{$break-sm}) {
      max-width: 31.25rem;
      margin: 0 auto;
    }
    @media only screen and (min-width: #{$break-md}) {
      max-width: 18.75rem;
      min-width: 18.75rem;
    }
  }

  &-text {
    padding: 1.25rem 0 0 0;

    @media only screen and (min-width: #{$break-md}) {
      padding: 0 1.25rem;
    }
  }
}
