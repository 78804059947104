@import '../../styles/variables';

.App-Article {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  @media only screen and (min-width: #{$break-md}) {
    flex-direction: row;
  }

  &-pic {
    display: block;
    margin-bottom: 2rem;
    margin-right: 0;

    img {
      width: 100%;
    }

    @media only screen and (min-width: #{$break-md}) {
      min-width: 400px;
      max-width: 600px;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
}
