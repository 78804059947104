@import '../../styles/variables';

.App-img {
  max-width: 100%;
  margin: 0;

  &--framed {
    border: 0.625rem solid $color-white;
  }

  &--bordered {
    border: $border-thin solid $color-neutral-mid;
  }

  &--rounded {
    border-radius: 0.625rem;
  }

  &--elevation-1 {
    box-shadow: 0 0.1875rem 0.375rem $color-shadows-dark;
  }
}
