@keyframes rotateIn {
  0% {
    transform: rotate(0deg);
    opacity: 0;
  }
  100% {
    transform: rotate(-3deg);
    opacity: 1;
  }
}

@keyframes rotateBack {
  0% {
    transform: rotate(-3deg);
    opacity: 1;
  }
  100% {
    transform: rotate(0deg);
    opacity: 0;
  }
}
