@import '../../styles/variables';

.App-KeyValue {
  // color: $color-white;
  text-align: center;
  max-width: 22.5rem;
  margin: 0 auto;

  .App-KeyValue-icon {
    display: block;
    color: inherit;
    margin-bottom: 1.25rem;
  }
}
