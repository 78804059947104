$break-xs: 0px;
$break-sm: 600px;
$break-md: 960px;
$break-lg: 1200px;

$color-white: #ffffff;

$color-primary-dark: #0c2935;
$color-primary: #103544;
$color-primary-light: #1a5870;

$color-action: #da3860;
$color-action-dark: #b83052;

$color-neutral: #eeeeee;
$color-neutral-mid: #dddddd;
$color-neutral-dark: #cccccc;
$color-neutral-darker: #504f4f;

$color-text-primary: #707070;
$color-text-light: #ffffff;

$color-shadows: rgba(0, 0, 0, 0.1);
$color-shadows-dark: rgba(0, 0, 0, 0.2);

$color-haze-overlay: rgba(255, 255, 255, 0.5);

$border-radius: 0.5rem;

$border-thin: 0.0625rem;

:export {
  colorPrimary: $color-primary;
  colorPrimaryDark: $color-primary-dark;
  colorAction: $color-action;
}
