@import '../../styles/variables';

.App-Carousel {
  display: block;
}

.App-Carousel-indicator--active {
  circle {
    fill: $color-action;
  }
}

.App-carousel-nav-item {
  cursor: pointer;
  position: absolute;
  top: 12.75rem;
  font-size: 2.5rem;
  margin-top: -1.25rem;
  padding: 0;
  border: none;
  height: 2.5rem;
  line-height: 2.5rem;
  color: $color-primary-dark;
  width: 2.5rem;
  background: $color-haze-overlay;
  border-radius: 1.25rem;

  @media only screen and (min-width: #{$break-md}) {
    top: 50%;
  }
}
